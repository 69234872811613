import cookie from "react-cookies";
import moment from "moment";

import * as Oidc from "oidc-client";
import * as axios from "axios";
import oauthConfig from "../config/oauthConfig";

import { STEER_DEV_AUTH_API, API_HOST } from "../config/settings";
import { addError } from "./error";

import {
  getAccessTokenQuery, getLocationHref, getToken, getApiTokenQuery,
  getClientTokenQuery, hasAccessToken, inIframe, isCrossOriginFrame
} from "../modules/query";

const queryString = require("query-string");

export const SAVE_USER = "SAVE_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const INITIALISING_USER = "INITIALISING_USER";
export const INITIALISING_USER_FAIL = "INITIALISING_USER_FAIL";
export const INITIALISING_USER_SUCCESS = "INITIALISING_USER_SUCCESS";
export const VERIFY_USER = "VERIFY_USER";
export const SAVE_PENDING_INVITES = "SAVE_PENDING_INVITES";
export const REMOVE_PENDING_INVITE = "REMOVE_PENDING_INVITE";
export const LOGIN_IDENTITY_SERVER = "LOGIN_IDENTITY_SERVER";

const expires = moment().add(1, "month").toDate();

function authenticatePage() {
  if (window.location.pathname === "/login") {
    return false;
  } else if (window.location.pathname === "/signup") {
    return false;
  } else if (window.location.pathname.indexOf("/b/") > -1) {
    return false;
  } else if (window.location.pathname === "/passwordReset") {
    return false;
  } else if (window.location.pathname === "/invite") {
    return false;
  } else if (window.location.pathname === "/feedback") {
    return false;
  } else if (window.location.pathname.indexOf("embedded") > -1) {
    return false;
  }

  // window.location.pathname = "/login";
  return true;
}

const mgr = new Oidc.UserManager(oauthConfig);

export function loginToQR() {
  //  if (!cookie.load("quickreach")) {
  mgr.signinRedirect();
  //  }
}

function redirectToDashboard() {
  if (
    window.location.pathname === "/login"
    || window.location.pathname === "signup"
  ) {
    window.location.href = `${getLocationHref()}/user${getClientTokenQuery(false, true)}`;
    return true;
  }

  return false;
}

export function saveUser(user) {
  return {
    type: SAVE_USER,
    user,
  };
}

export function saveTeamInvites(invites) {
  return {
    type: SAVE_PENDING_INVITES,
    invites,
  };
}

export function removeTeamInvite(removeInvite) {
  return {
    type: REMOVE_PENDING_INVITE,
    removeInvite,
  };
}

export function createUser(data) {
  return (dispatch) => {
    const url = `${API_HOST}/user${getApiTokenQuery()}`;
    const body = JSON.stringify(data);
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    const method = "POST";

    return fetch(url, { method, body, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }
        return response.json();
      })
      .then((user) => {
        // save the cookie here
        try {
          if (cookie.load("brewToken")) cookie.remove("brewToken", { path: "/" });
          cookie.save("brewToken", user.token, { expires, path: "/" });
        // eslint-disable-next-line no-empty
        } catch (err) { }
        // dispatch({ type: INITIALISING_USER_SUCCESS, user });
        dispatch(saveUser(user));
        return new Promise((resolve) => resolve(user));
      })
      .catch((error) => {
        dispatch({ type: INITIALISING_USER_FAIL, error });
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function oneaccountAuth(user) {
  return (dispatch) => {
    // save the cookie here
    try {
      if (cookie.load("brewToken")) cookie.remove("brewToken", { path: "/" });
      cookie.save("brewToken", user.token, { expires, path: "/" });
    // eslint-disable-next-line no-empty
    } catch (err) { }
    dispatch(saveUser(user));
    return new Promise((resolve) => resolve(user));
  };
}

export function qraccountAuth(user) {
  return (dispatch) => {
    // save the cookie here
    try {
      if (cookie.load("brewToken")) cookie.remove("brewToken", { path: "/" });
      cookie.save("brewToken", user.token, { expires, path: "/" });
    // eslint-disable-next-line no-empty
    } catch (err) { }
    dispatch(saveUser(user));
    return new Promise((resolve) => resolve(user));
  };
}

export function addEmailToList(email) {
  return (dispatch) => {
    const url = `${API_HOST}/user/email${getApiTokenQuery()}`;
    const body = JSON.stringify({ email });
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    const method = "POST";

    return fetch(url, { method, body, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }
        return response.json();
      })
      .then((user) => {
        return new Promise((resolve) => resolve(user));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function updateUser(id, data) {
  return (dispatch) => {
    const token = getToken();
    if (!token) {
      return new Promise((resolve, reject) => reject(new Error("No Token")));
    }
    const url = `${API_HOST}/user/${id}${getApiTokenQuery()}`;
    const method = "PUT";
    const body = JSON.stringify(data);
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    });
    return fetch(url, { method, body, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }

        return response.json();
      })
      .then((user) => {
        dispatch(saveUser(user));
        return new Promise((resolve) => resolve(user));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function deleteUser(id) {
  return (dispatch) => {
    const token = getToken();
    if (!token) {
      return new Promise((resolve, reject) => reject(new Error("No Token")));
    }
    const url = `${API_HOST}/user/${id}${getApiTokenQuery()}`;
    const method = "DELETE";
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    });
    return fetch(url, { method, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }
        return response.json();
      })
      .then((user) => {
        try {
          cookie.remove("brewToken", { path: "/" });
        // eslint-disable-next-line no-empty
        } catch (err) { }
        dispatch({ type: LOGOUT_USER });
        return new Promise((resolve) => resolve(user));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function createInvitedUser(data) {
  return (dispatch) => {
    const url = `${API_HOST}/user/invited${getApiTokenQuery()}`;
    const body = JSON.stringify(data);
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    const method = "POST";

    return fetch(url, { method, body, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }
        return response.json();
      })
      .then((user) => {
        try {
          if (cookie.load("brewToken")) cookie.remove("brewToken", { path: "/" });
          cookie.save("brewToken", user.token, { expires, path: "/" });
        // eslint-disable-next-line no-empty
        } catch (err) { }
        dispatch(saveUser(user));
        return new Promise((resolve) => resolve(user));
      })
      .catch((error) => {
        dispatch({ type: INITIALISING_USER_FAIL, error });
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function verify(id, token) {
  return (dispatch) => {
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    return fetch(`${API_HOST}/user/${id}/verify${getApiTokenQuery()}`, { method: "GET", headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          throw new Error("Could not verify email.");
        }

        return response.json();
      })
      .then((user) => {
        // save the cookie here
        try {
          cookie.remove("brewToken", { path: "/" });
          cookie.save("brewToken", user.token, { expires, path: "/" });
        // eslint-disable-next-line no-empty
        } catch (err) { }
        dispatch(saveUser(user));
        return new Promise((resolve) => resolve(user));
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err));
      });
  };
}

export function login(data) {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });
  const body = JSON.stringify(data);
  return (dispatch) => {
    return fetch(`${API_HOST}/user/login${getApiTokenQuery()}`, { method: "POST", headers, body })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status, "Couldn't login"));
          throw new Error("Couldn't login");
        }
        return response.json();
      })
      .then((user) => {
        // save the cookie here
        try {
          if (cookie.load("brewToken")) cookie.remove("brewToken", { path: "/" });
          cookie.save("brewToken", user.token, { expires, path: "/" });
        // eslint-disable-next-line no-empty
        } catch (err) { }
        dispatch(saveUser(user));
        return new Promise((resolve) => resolve(user));
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err.message));
      });
  };
}

function proceedRelog(dispatch, token) {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    authorization: `Bearer ${token}`,
  });
  const method = "POST";
  const url = `${API_HOST}/user/relog${getApiTokenQuery()}`;

  return fetch(url, { method, headers })
    .then((response) => {
      if (!response.ok) {
        dispatch(addError(response.status));
        return new Promise((resolve, reject) => reject("Couldn't relog"));
      }
      return response.json();
    })
    .then((user) => {
      redirectToDashboard();
      return new Promise((resolve) => resolve(user));
    })
    .catch(() => {
      if (authenticatePage()) {
        window.location.pathname = "/login";
      }
      return new Promise((resolve, reject) => reject("Can't authenticate the user"));
    });
}
function redirectToMainboard(resp) {
  if (inIframe()) {
    if (
      window.location.pathname === "/login"
      || window.location.pathname === "signup"
    ) {
      try {
        if (cookie.load("brewToken")) {
          window.location.href = `${getLocationHref()}/user${getApiTokenQuery(false)}`;
        } else if (!cookie.load("brewToken")) {
          window.location.href = `${getLocationHref()}/user?client_token=${resp.data.token}${getApiTokenQuery(true)}`;
        }
      } catch (err) {
        window.location.href = `${getLocationHref()}/user?client_token=${resp.data.token}${getApiTokenQuery(true)}`;
      }
    }
  } else if (!inIframe()
    && (window.location.pathname === "/login"
      || window.location.pathname === "signup")) {
    window.location.href = `${getLocationHref()}/user${getApiTokenQuery()}`;
  }
}
function processSingleSignin(entry, resp) {
  try {
    if (cookie.load("brewToken")) cookie.remove("brewToken", { path: "/" });
    cookie.save("brewToken", resp.data.token, { expires, path: "/" });
    if (entry && entry.length > 0) {
      if (cookie.load("brewToken")) {
        window.location.href = `${getLocationHref()}${entry}${getApiTokenQuery(false)}`;
      } else if (!cookie.load("brewToken")) {
        const locHref = `${getLocationHref()}${entry}?client_token=${resp.data.token}${getApiTokenQuery(true)}`;
        window.location.href = locHref;
      }
    } else redirectToMainboard(resp);
  } catch (err) {
    if (entry && entry.length > 0) {
      const locHref = `${getLocationHref()}${entry}?client_token=${resp.data.token}${getApiTokenQuery(true)}`;
      window.location.href = locHref;
    } else if (inIframe()
      && (
        window.location.pathname === "/login"
        || window.location.pathname === "signup"
      )) {
      try {
        if (cookie.load("brewToken")) {
          window.location.href = `${getLocationHref()}/user${getApiTokenQuery(false)}`;
        } else if (!cookie.load("brewToken")) {
          window.location.href = `${getLocationHref()}/user?client_token=${resp.data.token}${getApiTokenQuery(true)}`;
        }
      } catch (err) {
        window.location.href = `${getLocationHref()}/user?client_token=${resp.data.token}${getApiTokenQuery(true)}`;
      }
    } else if (!inIframe()
    && (
      window.location.pathname === "/login"
      || window.location.pathname === "signup"
    )
    ) {
      window.location.href = `${getLocationHref()}/user${getApiTokenQuery()}`;
    }
  }
}

function trySingleLogin(entry) {
  if (window.location.search) {
    const parsed = queryString.parse(window.location.search);
    if (parsed && parsed.access_token && parsed.access_token.length > 0) {
      // eslint-disable-next-line dot-notation
      axios.defaults.headers.common["Authorization"] = `Bearer ${parsed.access_token}`;
      axios.get(`${STEER_DEV_AUTH_API}?encrypt=false`)
        .then(response => {
          if (response.data && response.data.username && response.data.userId
                 && response.data.fullName && response.data.accessKey) {
            axios.post(`${API_HOST}/user/single?access_token=${parsed.access_token}`, {
              username: response.data.username,
              fullName: response.data.fullName,
              userId: response.data.userId,
              accessKey: response.data.accessKey
            })
              .then(resp => {
                processSingleSignin(entry, resp);
              })
            // eslint-disable-next-line no-unused-vars
              .catch(exp => {
                window.location.href = `${getLocationHref()}/login`;
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(exp => {
          window.location.href = `${getLocationHref()}/login`;
        });
    } else window.location.href = `${getLocationHref()}/login${getAccessTokenQuery(false)}&entry=${window.location.pathname}`;
  } else window.location.href = `${getLocationHref()}/login${getAccessTokenQuery(false)}&entry=${window.location.pathname}`;
  return new Promise((resolve, reject) => reject("Token is missing"));
}
export function relog() {
  return (dispatch) => {
    const token = getToken();
    if (!token) {
      if (authenticatePage()) {
        if (hasAccessToken()) {
          // eslint-disable-next-line max-len
          // window.location.href = `${getLocationHref()}/login${getAccessTokenQuery(false)}&entry=${window.location.pathname}`;
          if (inIframe() && isCrossOriginFrame()) return trySingleLogin(window.location.pathname);
          else {
            window.location.href = `${getLocationHref()}/login${getAccessTokenQuery(false)}&entry=${window.location.pathname}`;
            return new Promise((resolve, reject) => reject("Token is missing"));
          }
        } else {
          window.location.pathname = "/login";
          return new Promise((resolve, reject) => reject("Token is missing"));
        }
      } else if (hasAccessToken()) {
        return trySingleLogin(window.location.pathname);
      } else {
        window.location.pathname = "/login";
        return new Promise((resolve, reject) => reject("Token is missing"));
      }
    } else if (!token && hasAccessToken()) {
      if (authenticatePage()) {
        window.location.href = `${getLocationHref()}/login${getAccessTokenQuery(false)}`;
        return new Promise((resolve, reject) => reject("Relogin needed"));
      }
    }
    return proceedRelog(dispatch, token);
  };
}

export function getUser(id) {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });
  return (dispatch) => {
    return fetch(`${API_HOST}/user/${id}${getApiTokenQuery()}`, { method: "GET", headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status, "Couldn't get requested user"));
          throw new Error("Couldn't get requested user");
        }
        return response.json();
      })
      .then((user) => {
        dispatch(saveUser(user));
        return new Promise((resolve) => resolve(user));
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err.message));
      });
  };
}

export function getPendingInvites(id) {
  // get team invites for a specific user
  return (dispatch) => {
    const token = getToken();
    if (!token) {
      return new Promise((resolve, reject) => reject(new Error("No Token")));
    }
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    });
    return fetch(`${API_HOST}/user/${id}/teamInvites${getApiTokenQuery()}`, {
      method: "GET",
      headers,
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }
        return response.json();
      })
      .then((data) => {
        dispatch(saveTeamInvites(data));
        return new Promise((resolve) => resolve(data));
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err));
      });
  };
}

export function logout() {
  return (dispatch) => {
    try {
      cookie.remove("brewToken", { path: "/" });
    // eslint-disable-next-line no-empty
    } catch (err) { }
    // window.location.pathname = "/";
    if (window.location.href.indexOf("?") >= 0) {
      // eslint-disable-next-line prefer-destructuring
      window.location.href = window.location.href.split("?")[0];
    } else window.location.pathname = "/";
    dispatch({ type: LOGOUT_USER });
  };
}

export function sendFeedback({ name, feedback, email }) {
  return (dispatch) => {
    const body = JSON.stringify({
      from: name,
      // eslint-disable-next-line object-shorthand
      email: email,
      data: feedback,
    });
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    return fetch(`${API_HOST}/user/feedback${getApiTokenQuery()}`, { method: "POST", body, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }
        return response.json();
      })
      .then((data) => {
        return new Promise((resolve) => resolve(data));
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err));
      });
  };
}

export function requestPasswordReset(email) {
  return (dispatch) => {
    const url = `${API_HOST}/user/password/reset${getApiTokenQuery()}`;
    const method = "POST";
    const body = JSON.stringify({ email });
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    return fetch(url, { method, body, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          throw new Error(response.status);
        }
      })
      .then((result) => {
        return new Promise((resolve) => resolve(result));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function changePasswordWithToken(data) {
  return (dispatch) => {
    const url = `${API_HOST}/user/password/change${getApiTokenQuery()}`;
    const method = "PUT";
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });
    const body = JSON.stringify(data);

    return fetch(url, { method, body, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.status));
        }

        return response.json();
      })
      .then((result) => {
        return new Promise((resolve) => resolve(result));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}
