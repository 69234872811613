/* eslint-disable prefer-template */
// eslint-disable-next-line no-unused-vars
import cookie from "react-cookies";
import { getToken } from "../modules/query";

const oauthConfig = {
  authority: "https://live.quickreach.co/identity",
  /* client_id: 'qrp-spa-local', */
  client_id: "qrcharts",
  redirect_uri: window.location.origin + "/oidc-redirect-callback",
  post_logout_redirect_uri: window.location.origin + "/",
  response_type: "id_token token",
  scope: "openid profile",
  // this will toggle if profile endpoint is used
  loadUserInfo: true,
  // silent renew will get a new access_token via an iframe
  // just prior to the old access_token expiring (60 seconds prior)
  silent_redirect_uri: window.location.origin + "/oidc-silent-redirect",
  automaticSilentRenew: true,

  // will revoke (reference) access tokens at logout time
  revokeAccessTokenOnSignout: true,

  // this will allow all the OIDC protocol claims to be visible in the window. normally a client app
  // wouldn't care about them or want them taking up space
  filterProtocolClaims: false,
};

export default oauthConfig;

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getQRTenant() {
  try {
    if (!cookie.load("brewToken")) {
      const cookie = getToken();
      if (cookie && cookie.length > 0) {
        const token = parseJwt(cookie);
        return token.tenantId;
      } else return "No Token";
    }
  } catch (err) {
    const cookie = getToken();
    if (cookie && cookie.length > 0) {
      const token = parseJwt(cookie);
      return token.tenantId;
    } else return "No Token";
  }
  try {
    const cookie = cookie.load("brewToken");
    const token = parseJwt(cookie);
    return token.tenantId;
  } catch (err) {
    const cookie = getToken();
    if (cookie && cookie.length > 0) {
      const token = parseJwt(cookie);
      return token.tenantId;
    } else return "No Token";
  }
}
