// eslint-disable-next-line no-unused-vars
import { getQRTenant } from "../config/oauthConfig";

import { API_HOST } from "../config/settings";
import { getProject } from "./project";
import { addError } from "./error";

import { getToken, getApiTokenQuery } from "../modules/query";

export const FETCHING_CONNECTION = "FETCHING_CONNECTION";
export const FETCH_CONNECTION_SUCCESS = "FETCH_CONNECTION_SUCCESS";
export const FETCH_ALL_CONNECTIONS = "FETCH_ALL_CONNECTIONS";
export const FETCH_CONNECTION_FAIL = "FETCH_CONNECTION_FAIL";

export function getProjectConnections(projectId) {
  return (dispatch) => {
    const token = getToken();
    if (!token) {
      return new Promise((resolve, reject) => reject(new Error("No Token")));
    }
    /* if (!cookie.load("quickreach")) {
      return new Promise((resolve, reject) =>
        reject(new Error("No QuickReach Token"))
      );
    } */
    // const qrtoken = cookie.load("quickreach");

    const url = `${API_HOST}/project/${projectId}/connection${getApiTokenQuery()}`;
    const method = "GET";
    const headers = new Headers({
      Accept: "application/json",
      authorization: `Bearer ${token}`,
    });

    dispatch({ type: FETCHING_CONNECTION });
    return fetch(url, { method, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }

        return response.json();
      })
      .then((connections) => {
        dispatch({ type: FETCH_ALL_CONNECTIONS, connections });
        return new Promise((resolve) => resolve(connections));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function addConnection(projectId, connection) {
  return (dispatch) => {
    const token = getToken();
    if (!token) {
      return new Promise((resolve, reject) => reject(new Error("No Token")));
    }
    const url = `${API_HOST}/project/${projectId}/connection${getApiTokenQuery()}`;
    const method = "POST";
    const body = JSON.stringify(connection);
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    });

    dispatch({ type: FETCHING_CONNECTION });
    return fetch(url, { method, body, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          throw new Error(response.status);
        }

        return response.json();
      })
      .then((newConnection) => {
        dispatch({ type: FETCH_CONNECTION_SUCCESS, connection: newConnection });
        dispatch(getProject(newConnection.project_id, true));
        return new Promise((resolve) => resolve(newConnection));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function saveConnection(projectId, connection) {
  return (dispatch) => {
    const token = getToken();
    if (!token) {
      return new Promise((resolve, reject) => reject(new Error("No Token")));
    }
    const url = `${API_HOST}/project/${projectId}/connection/${connection.id}${getApiTokenQuery()}`;
    const method = "PUT";
    const body = JSON.stringify(connection);
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    });

    dispatch({ type: FETCHING_CONNECTION });
    return fetch(url, { method, body, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          throw new Error(response.status);
        }

        return response.json();
      })
      .then((newConnection) => {
        dispatch({ type: FETCH_CONNECTION_SUCCESS, connection: newConnection });
        dispatch(getProject(newConnection.project_id, true));
        return new Promise((resolve) => resolve(newConnection));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function testConnection(projectId, id) {
  return (dispatch) => {
    const token = getToken();
    if (!token) {
      return new Promise((resolve, reject) => reject(new Error("No Token")));
    }
    const url = `${API_HOST}/project/${projectId}/connection/${id}/test${getApiTokenQuery()}`;
    const method = "GET";
    const headers = new Headers({
      Accept: "application/json",
      authorization: `Bearer ${token}`,
    });

    return fetch(url, { method, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }

        return response.json();
      })
      .then((test) => {
        return new Promise((resolve) => resolve(test));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function testRequest(projectId, data) {
  return () => {
    const token = getToken();
    if (!token) {
      return new Promise((resolve, reject) => reject(new Error("No Token")));
    }
    const url = `${API_HOST}/project/${projectId}/connection/${data.type}/test${getApiTokenQuery()}`;
    const method = "POST";
    const headers = new Headers({
      Accept: "application/json",
      "Content-type": "application/json",
      authorization: `Bearer ${token}`,
    });
    const body = JSON.stringify(data);

    return fetch(url, { method, body, headers })
      .then((response) => {
        return new Promise((resolve) => resolve(response));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function updateConnection(projectId, id, data) {
  return (dispatch) => {
    const token = getToken();
    if (!token) {
      return new Promise((resolve, reject) => reject(new Error("No Token")));
    }
    const url = `${API_HOST}/project/${projectId}/connection/${id}${getApiTokenQuery()}`;
    const method = "PUT";
    const body = JSON.stringify(data);
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    });

    dispatch({ type: FETCHING_CONNECTION });
    return fetch(url, { method, body, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }

        return response.json();
      })
      .then((connection) => {
        dispatch({ type: FETCH_CONNECTION_SUCCESS, connection });
        return new Promise((resolve) => resolve(connection));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}

export function removeConnection(projectId, id) {
  return (dispatch) => {
    const token = getToken();
    if (!token) {
      return new Promise((resolve, reject) => reject(new Error("No Token")));
    }
    const url = `${API_HOST}/project/${projectId}/connection/${id}${getApiTokenQuery()}`;
    const method = "DELETE";
    const headers = new Headers({
      Accept: "application/json",
      authorization: `Bearer ${token}`,
    });

    return fetch(url, { method, headers })
      .then((response) => {
        if (!response.ok) {
          dispatch(addError(response.status));
          return new Promise((resolve, reject) => reject(response.statusText));
        }

        return response.json();
      })
      .then(() => {
        return new Promise((resolve) => resolve(true));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => reject(error));
      });
  };
}
